import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import DataStatistics from "./Pages/Data-Statistics";
import MachineLearning from "./Pages/ML-Results";
import NavBar from "./components/NavBar";
import PageNotFound from "./Pages/PageNotFound";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/data-statistics" element={<DataStatistics />} />
        <Route path="/machine-learning" element={<MachineLearning />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
