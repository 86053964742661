import { Link } from "react-router-dom";
import "./PageNotFound.css";

function PageNotFound() {
  return (
    <div className="error-wrapper">
      <h2>Oops! Something went wrong.</h2>
      <h1>404</h1>
      <p>
        We can't seem to find the page you are looking for.{"\n"}
        <Link to="/">Return Home</Link>
      </p>
    </div>
  );
}

export default PageNotFound;
