import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import "./Gallery.css";

function Gallery(props) {
  return (
    <div className="gallery-container">
      <div className="carousel-container">
        <Carousel
          isLoop={false}
          hasMediaButton={false}
          hasDotButtons="bottom"
          hasThumbnails={false} // For bottom thimbnales sections
          shouldSwipeOnMouse={false} // for selecting text
          shouldMinimizeOnSwipeDown={false} // for vertical overflow scrolling
          style={{
            userSelect: "text",
          }}
          children={props.children}
        />
      </div>

      <div className="description-container">
        <h2 className="description-title">{props.title}</h2>

        <p className="description-body">{props.description}</p>
      </div>
    </div>
  );
}

export default Gallery;
