import { NavLink } from "react-router-dom";
import Logo from "../assets/pyramid-full.png";
import "./NavBar.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faChartLine,
  faMicrochip,
} from "@fortawesome/free-solid-svg-icons";

function NavBar() {
  return (
    <header>
      <nav>
        <ul className="nav-links">
          <li>
            <NavLink to="/" exact activeClassName="active">
              <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon> Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/data-statistics" exact activeClassName="active">
              <FontAwesomeIcon icon={faChartLine}></FontAwesomeIcon> Data
              Statistics
            </NavLink>
          </li>
          <li>
            <NavLink to="/machine-learning" exact activeClassName="active">
              <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> Machine
              Learning Results
            </NavLink>
          </li>
        </ul>
      </nav>
      <img src={Logo} alt="Logo for Pyramid" className="logo-full" />
    </header>
  );
}

export default NavBar;
