import React from "react";
import Gallery from "../components/Gallery";
import GoToTop from "../utils/GoToTop";
import "./Data-Statistics.css";

function DataStatistics() {
  // this demonstrates that we can pass in html objects as props to render in `Galleries`
  // probably replace these with ploty generated html
  let bank_children = [
    <iframe
      src="https://storage.googleapis.com/pyramindintern-bucket-html/DATA_choropleth_FDIC_map.html"
      height="100%"
      width="100%"
      title="FDIC Choropleth Map"
    ></iframe>,
  ];

  let disaster_children = [
    <iframe
      src="https://storage.googleapis.com/pyramindintern-bucket-html/DATA_choropleth_FEMA_map.html"
      height="100%"
      width="100%"
      title="FEMA Choropleth Map"
    ></iframe>,
    <iframe
      src="https://storage.googleapis.com/pyramindintern-bucket-html/DATA_incident_type_map.html"
      height="100%"
      width="100%"
      title="Disaster Distribution"
    ></iframe>,
    <iframe
      src="https://storage.googleapis.com/pyramindintern-bucket-html/DATA_incident_type_bar.html"
      height="100%"
      width="100%"
      title="Incident Type"
    ></iframe>,
    <iframe
      src="https://storage.googleapis.com/pyramindintern-bucket-html/DATA_incident_type_bar_slider.html"
      height="100%"
      width="100%"
      title="Incident Type Years"
    ></iframe>,
  ];

  return (
    <div className="container">
      <h1 className="page-title">Data Statistics</h1>

      <Gallery
        children={bank_children}
        title={"Bank Data"}
        description={`Looking at the FDIC data, there is a strong relation between urban areas and the total count of banks. However, our visualization does not go beyond the county level and leads to larger counties by land area having more banks although they may not have as high of a bank density. For example, San Bernardino County has a large population concentrated in a small area compared to the rest of the county, but the majority of its land area is sparsely populated desert. `}
      />

      <Gallery
        children={disaster_children}
        title={"Disaster Data"}
        description={`The FEMA disaster choropleth map shows that the data corresponds well geographically to the natural disaster hot zones such as the Gulf Coast and Tornado Alley.\n\nToggling the individual disaster types in the second graph, the scatter plot further highlights the most common disasters each area is susceptible to. For example the earthquakes on the West Coast fault line, and the snow storms in the Northeast.\n\nNote that due to the nationwide COVID outbreaks, there are “biological disasters“ in every county, masking the distribution of other disaster types, toggle it off to reveal the other disasters.Furthermore, due to the FEMA data being mapped to the county level, the disaster locations are mapped to the same point regardless of where the disaster occurred within the county.
        `}
      />
      <GoToTop />
    </div>
  );
}

export default DataStatistics;
