import React, { useEffect } from "react";
import "./Home.css";
import "../index.css";
import GoToTop from "../utils/GoToTop";

function Home() {
  // Allows overflow to be hidden on just this page
  useEffect(() => {
    // Applying on mount
    document.body.style.overflowX = "hidden";
    // Applying on unmount
    return () => {
      document.body.style.overflowX = "visible";
    };
  }, []);

  return (
    <div className="Content-Wrapper">
      {/* Title Section */}
      <section id="title" className="pgsection">
        <div className="title-container">
          <h1>Pyramid Data Pipeline to Drive ML/AI Models</h1>

          <svg
            className="orb"
            width="635"
            height="1000"
            viewBox="0 0 635 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="542"
              cy="500"
              rx="542"
              ry="500"
              fill="url(#paint0_radial_46_33)"
              fill-opacity="0.5"
            />
            <defs>
              <radialGradient
                id="paint0_radial_46_33"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(542 500) rotate(90) scale(500 542)"
              >
                <stop
                  offset="0.421875"
                  stop-color="#FE8E26"
                  stop-opacity="0.59"
                />
                <stop offset="1" stop-color="#E57333" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </section>
      {/* Page Section */}
      <section id="page">
        {/* Our Objective Section */}
        <section className="pgsection reveal objective">
          <div className="objective-container">
            <i className="icon flagcon fa-solid fa-flag fa-3x"></i>
            <h3 className="subtitle-home">Our Objective</h3>
            <p className="paragraph-home">
              Develop an automated data pipeline that will ingest, prepare, and
              analyze open-source data; create various models and visualizations
              using that data; and develop a web application to deliver the
              model to the public.
            </p>
          </div>

          <svg
            className="orb-op"
            width="635"
            height="1000"
            viewBox="0 0 635 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="542"
              cy="500"
              rx="542"
              ry="500"
              fill="url(#paint0_radial_46_33)"
              fill-opacity="0.5"
            />
            <defs>
              <radialGradient
                id="paint0_radial_46_33"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(542 500) rotate(90) scale(500 542)"
              >
                <stop
                  offset="0.421875"
                  stop-color="#FE8E26"
                  stop-opacity="0.59"
                />
                <stop offset="1" stop-color="#E57333" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </section>

        {/* Dataset Section */}
        <section className="pgsection reveal dataset">
          <div className="datasets-container">
            <i className="icon datacon fa-solid fa-database fa-3x"></i>
            <h3 className="subtitle-home">Datasets</h3>
            <p>
              <a
                className="link"
                href="https://www.fema.gov/openfema-data-page/disaster-declarations-summaries-v2"
              >
                Disaster Data from Federal Emergency Management Agency (FEMA)
              </a>
            </p>
            <p>
              <a className="link" href="https://banks.data.fdic.gov/docs/">
                Bank Data from Federal Deposit Insurance Corporation (FDIC)
              </a>
            </p>
            <p>
              <a
                className="link"
                href="https://www.census.gov/geographies/reference-files/time-series/geo/gazetteer-files.html"
              >
                US Census Bureau Data
              </a>
            </p>
          </div>

          <svg
            className="orb"
            width="635"
            height="1000"
            viewBox="0 0 635 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="542"
              cy="500"
              rx="542"
              ry="500"
              fill="url(#paint0_radial_46_33)"
              fill-opacity="0.5"
            />
            <defs>
              <radialGradient
                id="paint0_radial_46_33"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(542 500) rotate(90) scale(500 542)"
              >
                <stop
                  offset="0.421875"
                  stop-color="#FE8E26"
                  stop-opacity="0.59"
                />
                <stop offset="1" stop-color="#E57333" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </section>

        {/* Data Description Section */}
        <section className="pgsection reveal datadesc">
          <div className="data-desc-container">
            <i className="icon desccon fa-solid fa-file-waveform fa-3x"></i>
            <h3 className="subtitle-home">Data Descriptions</h3>
            <h4 className="datadesc-sub">FEMA</h4>
            <p>
              FEMA Disaster Declarations Summary is a summarized dataset
              describing all federally declared disasters. This dataset lists
              all official FEMA Disaster Declarations, beginning with the first
              disaster declaration in 1953 to present day.
            </p>
            <h4 className="datadesc-sub">FDIC</h4>
            <p>
              FDIC's application programming interface (API) lets developers
              access FDIC's publically available bank data. The dataset lists
              FDIC-insured banks branches and locations across the United
              States.
            </p>
            <h4 className="datadesc-sub">US Census</h4>
            <p>
              The US Census Bureau provides county data for all 50 states, the
              District of Columbia, and Puerto Rico. These characteristics
              include a unique geoidentifier as well as a longitude and latitude
              for the exact location of where the county is located.
            </p>
          </div>
        </section>
      </section>
      <GoToTop />
    </div>
  );
}

export default Home;
